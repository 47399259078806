import { useEffect, useState } from "react"
import SideNav from "../../components/SideNav"

import DOCUMENTS from "../../models/Documents"
import { Link, useLocation, useSearchParams } from "react-router-dom"
import auth from "../../api/auth"

import banking from "../../assets/documents/bank-account.svg"

import documents_api from "../../api/documents"
import outgoings_api from "../../api/outgoings"
import knowledge_api from "../../api/knowledge"

import PlusUpgradeBanner from "../../components/PlusUpgradeBanner"
import stripe_api from "../../api/stripe"

const Dashboard = () => {
  const [assetsSections, setAssetsSections] = useState()
  const [futureSections, setFutureSections] = useState()
  const [insuranceSections, setInsuranceSections] = useState()

  const [dashboard, setDashboard] = useState()

  const [bankAccounts, setBankAccounts] = useState([])

  const [relatedGuides, setRelatedGuides] = useState()
  const { hash } = useLocation()
  const [scrolled, setScrolled] = useState(false)

  const [params, setParams] = useSearchParams()
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false)

  useEffect(() => {
    getArticles()
  }, [])

  useEffect(() => {
    if (hash && !scrolled && assetsSections) {
      let id = hash.replace("#", "")
      id = id.replaceAll("%20", " ")
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
        setScrolled(true)
      }
    }
  })

  const getArticles = () => {
    const response = knowledge_api.getSomeGuides(3)
    response
      .then((response) => {
        setRelatedGuides(response.data.articles)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    const a = []
    for (const key in DOCUMENTS.ASSETS_AND_LIABILITIES) a.push(DOCUMENTS.ASSETS_AND_LIABILITIES[key])
    setAssetsSections(a)

    const f = []
    for (const key in DOCUMENTS.FUTURE_PLANNING) f.push(DOCUMENTS.FUTURE_PLANNING[key])
    setFutureSections(f)

    const i = []
    for (const key in DOCUMENTS.INSURANCE) i.push(DOCUMENTS.INSURANCE[key])
    setInsuranceSections(i)
  }, [])

  const [user, setUser] = useState()

  useEffect(() => {
    if (!auth.isAuthenticated()) window.location.replace("/home")
    setUser(auth.getCurrentUserLocal())
  }, [])

  useEffect(() => {
    GetDashboard()
    GetAccounts()
  }, [])

  const GetDashboard = () => {
    const response = documents_api.getDashboard()
    response
      .then((response) => {
        setDashboard(response.data.dashboard)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const GetAccounts = () => {
    const response = outgoings_api.getAccounts()
    response
      .then((response) => {
        setBankAccounts(response.data.accounts)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (!params.get("reason")) return

    switch (params.get("reason")) {
      case "membership":
        setShowUpgradeDialog(true)
        setParams(params.delete("reason"))
        break
      default:
        setParams(params.delete("reason"))
        break
    }
  }, [params.get("reason")])

  const ToggleUpgradeBanner = () => {
    setShowUpgradeDialog(!showUpgradeDialog)
  }

  const CreateCheckOutSession = (type) => {
    const response = stripe_api.createCheckoutSession(type, "dashboard?subscribe=complete", "dashboard")
    response
      .then((response) => {
        const url = response.data.url
        window.location.replace(url)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="page-container">
      <SideNav />
      <div className="documents-subpage-container">
        <div className="documents-subpage-title">{user && `Welcome back, ${user.first_name}`} </div>

        <div className="documents-banking-section-container">
          <div className="documents-section-label">Banking</div>
          <div className="documents-banking-section-card-container">
            <Link to={"/banking"} className="document-banking-section-card-link">
              <div className="documents-section-card link">
                {!bankAccounts && !bankAccounts.length && <div className="tinter" />}
                <div className="documents-section-card-image-container">
                  <img className="documents-section-card-image" alt={"section logo image"} src={banking} />
                </div>
                <div className="documents-section-card-title">Bank account</div>
                {bankAccounts && bankAccounts.length && (
                  <p style={{ textAlign: "center", color: "green" }}>
                    {" "}
                    {bankAccounts.length} item{bankAccounts.length > 1 ? "s" : null}
                  </p>
                )}
              </div>
            </Link>
            <div className="document-banking-empty" />
            <div className="document-banking-empty" />
            <div className="document-banking-empty" />
          </div>
        </div>

        <div className="documents-assets-section-container">
          <div className="documents-section-label">Assets</div>
          <div className="documents-assets-section-cards-container link row">
            {assetsSections &&
              assetsSections.map((section, _idx) => {
                return (
                  <Link key={Math.random()} to={`/document/${section.link}`} className="dashboard-col">
                    <div className="documents-section-card link" id={section.title}>
                      {dashboard && !dashboard?.[section.link] && <div className="tinter" />}
                      <div className="documents-section-card-image-container">
                        <img className="documents-section-card-image" alt={"section logo image"} src={section.image} />
                      </div>
                      <div className="documents-section-card-title">{section.title}</div>
                      {dashboard && dashboard?.[section.link] ? (
                        <p style={{ textAlign: "center", color: "green" }}>
                          {" "}
                          {dashboard[section.link]} item{dashboard[section.link] > 1 ? "s" : null}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                )
              })}
          </div>
        </div>

        <div className="documents-future-section-container">
          <div className="documents-section-label">Planning</div>
          <div className="documents-assets-section-cards-container link row">
            {futureSections &&
              futureSections.map((section, _idx) => {
                return (
                  <Link key={Math.random()} to={`/document/${section.link}`} className="dashboard-col">
                    <div className="documents-section-card link" id={section.title}>
                      {dashboard && !dashboard?.[section.link] && <div className="tinter" />}
                      <div className="documents-section-card-image-container">
                        <img className="documents-section-card-image" alt={"section logo image"} src={section.image} />
                      </div>
                      <div className="documents-section-card-title">{section.title}</div>
                      {dashboard && dashboard?.[section.link] ? (
                        <p style={{ textAlign: "center", color: "green" }}>
                          {" "}
                          {dashboard[section.link]} item{dashboard[section.link] > 1 ? "s" : null}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                )
              })}
          </div>
        </div>

        <div className="documents-insurance-section-container">
          <div className="documents-section-label">Insurance</div>
          <div className="documents-assets-section-cards-container link row">
            {insuranceSections &&
              insuranceSections.map((section, _idx) => {
                return (
                  <Link key={Math.random()} to={`/document/${section.link}`} className="dashboard-col">
                    <div className="documents-section-card link" id={section.title}>
                      {dashboard && !dashboard?.[section.link] && <div className="tinter" />}
                      <div className="documents-section-card-image-container">
                        <img className="documents-section-card-image" alt={"section logo image"} src={section.image} />
                      </div>
                      <div className="documents-section-card-title">{section.title}</div>
                      {dashboard && dashboard?.[section.link] ? (
                        <p style={{ textAlign: "center", color: "green" }}>
                          {" "}
                          {dashboard[section.link]} item{dashboard[section.link] > 1 ? "s" : null}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                )
              })}
          </div>
        </div>

        <div className="dashboard-knowledge-hub-container">
          <div className="guide-article-cards-container">
            <div className="guide-heading-container">
              <div className="guide-heading">Knowledge hub</div>
              <div className="guide-description">All the knowledge on how to use the Snapshot platform.</div>
            </div>
            <div className="row">
              {relatedGuides &&
                relatedGuides.map((guide, _idx) => {
                  return (
                    <div key={Math.random()} className="col">
                      <Link to={`/guide/${guide.link}`} className="link">
                        <div className="guide-article-card-container">
                          <div className="guide-article-card-image-container" />
                          <div className="guide-article-card-details-container">
                            <div className="guide-article-card-title-container">
                              <div className="guide-article-card-title">{guide.title}</div>
                            </div>
                            <div className="guide-article-card-description">{guide.description}</div>
                            <div className="guide-article-card-read-article link">READ ARTICLE ></div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
      {showUpgradeDialog ? (
        <PlusUpgradeBanner createSession={CreateCheckOutSession.bind(this)} toggle={ToggleUpgradeBanner.bind(this)} />
      ) : null}
    </div>
  )
}

export default Dashboard
