import { Document, Page, StyleSheet, Text, View, Image, Font } from "@react-pdf/renderer"
import dateUtils from "./dateUtils"

import logo from "../assets/reports/logo.png"
import logo_dark from "../assets/reports/leaf.png"
import title_background from "../assets/reports/title_background.png"
import { getMerchantName, getTransactionAmount } from "../components/TransactionsTable"

import React from "react"

Font.register({
  family: "Inter",
  fonts: [{ src: "/fonts/Inter-Regular.ttf" }, { src: "/fonts/Inter-Bold.ttf", fontStyle: "bold" }],
})

const styles = StyleSheet.create({
  title: {
    fontSize: "30px",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "5%",
    marginBottom: "4%",
    textAlign: "left",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    marginTop: "2%",
    textAlign: "center",
    borderBottom: "1px solid #DCDCDC",
  },
  outgoings_heading: {
    width: "25%",
    fontSize: "16px",
  },
  outgoings_element: {
    width: "25%",
    fontSize: "12px",
  },
  contacts_heading: {
    width: "25%",
    fontSize: "16px",
  },
  contacts_element: {
    width: "25%",
    fontSize: "12px",
  },
  title_page_background: {
    position: "absolute",
    width: " 100%",
    height: "100%",
  },
  page: {
    color: "#000000",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    height: "100%",
    position: "relative",
    width: "100%",
    fontFamily: "Inter",
  },
  header_row: {
    flexDirection: "row",
    width: "90%",
    margin: "20px 30px",
    textAlign: "center",
    fontSize: "12px",
  },
  header_left: {
    textAlign: "left",
    width: "45%",
  },
  header_center: {
    textAlign: "center",
    width: "10%",
  },
  header_right: {
    textAlign: "right",
    width: "45%",
  },
  footer_image: {
    position: "absolute",
    left: "90%",
    top: "95%",
    width: "30px",
    backgroundColor: "transparent",
  },
  page_number: {
    margin: "10px 30px",
    letterSpacing: "5px",
    fontSize: "40px",
  },
  page_title: {
    margin: "10px 30px",
    letterSpacing: "-0.2",
    fontSize: "22px",
    width: "80%",
    fontFamily: "Inter",
    fontStyle: "bold",
    lineHeight: "5px",
  },
  page_title_second: {
    margin: "-10px 30px",
    letterSpacing: "-0.2",
    fontSize: "22px",
    width: "80%",
    fontFamily: "Inter",
    fontStyle: "bold",
  },
  page_sub_title: {
    margin: "10px 30px",
    letterSpacing: "2px",
    fontSize: "24px",
    width: "80%",
  },
  page_body: {
    margin: "20px 30px",
    paddingTop: "6px",
    fontSize: "14px",
    display: "block",
  },
  page_body_label: {
    margin: "10px 30px -20px",
    fontSize: "14px",
    fontStyle: "bold",
  },
  summary_body: {
    margin: "10px 30px",
    fontSize: "14px",
  },
  summary_list: {
    margin: "1px 30px",
    fontSize: "14px",
    display: "list-item",
  },
  summary_container: {
    backgroundColor: "rgb(29, 40, 78)",
    color: "#FFFFFF",
    height: "100%",
    marginTop: "100px",
  },
  page_body_bold: {
    margin: "20px 30px",
    fontSize: "16px",
    fontStyle: "bold",
  },
  contacts: {
    margin: "20px 30px",
    fontSize: "14px",
  },
  docs_line: {
    paddingTop: "5px",
    fontSize: "14px",
    lineHeight: "4px",
  },
  docs_line_bold: {
    paddingTop: "5px",
    fontSize: "14px",
    lineHeight: "5px",
    fontStyle: "bold",
    marginBottom: "5px",
  },
  contact_line: {
    paddingTop: "5px",
    fontSize: "14px",
    lineHeight: "1px",
  },
  contact_line_bold: {
    paddingTop: "5px",
    fontSize: "14px",
    lineHeight: "1px",
    fontStyle: "bold",
  },
  transaction: {
    flexDirection: "row",
    alignItems: "center",
    margin: "5px 30px",
    textAlign: "left",
    fontSize: "14px",
    borderBottom: "1px solid #dddddd",
  },
  transaction_element: {
    width: "33%",
    height: "30px",
    fontSize: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  transaction_name: {
    width: "50%",
    height: "22px",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  transaction_amount: {
    width: "30%",
    height: "22px",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  transaction_date: {
    width: "20%",
    height: "22px",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})

const generateFinancialDocument = (transactions, _contacts, user, accounts, _self = true) => {
  const nonce = document.querySelector("meta[name=nonce]").nonce
  const generatedDate = dateUtils.formatDateLong(new Date())
  const headerDate = dateUtils.formatDateReportHeader(new Date())

  const chunk_size = 15
  const groups = []
  if (!accounts || !transactions) {
    throw new TypeError("accounts and/or transactions were not provided")
  }
  for (const acc of accounts) {
    const accountTransactions = transactions.filter((tx) => tx.account_id === acc.external_account_id)
    for (let i = 0; i < accountTransactions.length; i += chunk_size) {
      const chunk = accountTransactions.slice(i, i + chunk_size)
      groups.push({ account: acc, num: groups.length, trxs: chunk })
    }
  }

  return (
    <Document>
      <Page size="A4">
        {/*TITLE PAGE  backgroundColor: "#25325E" */}
        <View key={Math.random()} nonce="fee4ef8fc1cf058dab5be8e0ad25ac8d" style={{ color: "#FFFFFF", height: "100%" }}>
          <Image src={title_background} nonce="fee4ef8fc1cf058dab5be8e0ad25ac8d" style={styles.title_page_background} />
          <div nonce="fee4ef8fc1cf058dab5be8e0ad25ac8d" style={{ marginLeft: "30px" }}>
            <Image src={logo} nonce="fee4ef8fc1cf058dab5be8e0ad25ac8d" style={{ width: "30%", marginTop: "200px" }} />
            <Text nonce="fee4ef8fc1cf058dab5be8e0ad25ac8d" style={{ width: "60%", fontSize: "50px", marginTop: "10px" }}>
              FINANCIAL REPORT
            </Text>
            <Text nonce={nonce} tyle={{ fontSize: "25px", marginTop: "60px" }}>
              FOR YOU
            </Text>
            <Text
              style={{
                fontSize: "25px",
                marginTop: "5px",
                textTransform: "uppercase",
              }}
            >
              {generatedDate}
            </Text>
          </div>
        </View>

        {/*INTRO PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              HOW TO USE THE REPORT
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              The Report is an important tool that gives the grieving family, dealing with loss a useful overview of the
              financial affairs of the Subscriber
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              The information within this report can help in a number of ways:
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              It tells you which banks need to be informed so that accounts can be frozen or closed. The report lists
              regular payments, like subscriptions, direct debits and standing orders to help you identify companies
              that need to be informed.
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              It helps provide an understanding of the liquid assets that might be required to help with funeral and
              burial costs.
            </Text>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*ABOUT PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              ABOUT THE MEMBER
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Full Name:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && `${user.first_name} ${user.last_name}`}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Date of Birth:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.dob_string}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Current Address:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.address}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Member From:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.created_at && dateUtils.formatDateLong(user.created_at)}
            </Text>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*OUTGOINGS PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              OPEN BANKING INFORMATION
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {`Snapshot is linked to the main bank account or accounts of ${user.first_name} ${user.last_name}. Our system extracts key information from the accounts to highlight recurring payments, such as Direct Debits, Standing Orders, Subscriptions and any other Regular Payments. Once a death certificate has been issued and probate is granted, the family will have full access to the accounts and have a full view of all transactions`}
            </Text>
            <Text nonce={nonce} style={styles.page_body_bold}>
              Bank Accounts Linked to Snapshot
            </Text>

            {accounts &&
              accounts.map((account, idx) => {
                return (
                  <>
                    {idx < 3 ? (
                      <div key={Math.random()} nonce={nonce} style={styles.contacts}>
                        <Text
                          nonce={nonce}
                          style={styles.contact_line_bold}
                        >{`${account.provider} ${account.name}`}</Text>
                        <Text nonce={nonce} style={styles.contact_line}>{`Sort Code: ${account.sort_code}`}</Text>
                        <Text
                          nonce={nonce}
                          style={styles.contact_line}
                        >{`Account Number: ${account.account_number}`}</Text>
                      </div>
                    ) : null}
                  </>
                )
              })}
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*OUTGOINGS CONTINUED 2 PAGE*/}
        {groups.map((g, idx) => {
          return (
            <View key={Math.random()} nonce={nonce} style={styles.page}>
              {/*Header*/}
              <div nonce={nonce} style={styles.header_row}>
                <Text nonce={nonce} style={styles.header_left}>
                  EXECUTOR REPORT
                </Text>
                <Text nonce={nonce} style={styles.header_center}>
                  //
                </Text>
                <Text nonce={nonce} style={styles.header_right}>
                  {headerDate}
                </Text>
              </div>
              {/* Body */}
              <div>
                <Text nonce={nonce} style={styles.page_title}>
                  OPEN BANKING INFORMATION continued
                </Text>
                <Text
                  nonce={nonce}
                  style={styles.page_body_bold}
                >{`Active Regular Payments${idx > 0 ? " continued" : ""}`}</Text>

                <div nonce={nonce} style={styles.contacts}>
                  <Text nonce={nonce} style={styles.contact_line_bold}>
                    {g.account && `${g.account.provider} ${g.account.name}`}
                  </Text>
                  <Text nonce={nonce} style={styles.contact_line}>
                    {g.account && `Sort Code: ${g.account.sort_code}`}
                  </Text>
                  <Text nonce={nonce} style={styles.contact_line}>
                    {g.account && `Account Number: ${g.account.account_number}`}
                  </Text>
                </div>

                {g.trxs &&
                  g.trxs.map((transaction, _idx) => {
                    return (
                      <>
                        <div key={Math.random()} nonce={nonce} style={styles.transaction}>
                          <Text nonce={nonce} style={styles.transaction_name}>
                            {/* {console.log('indiv tx', transaction)} */}
                            {/* {transaction.merchant_name ? transaction.merchant_name : transaction.transaction_description} */}
                            {getMerchantName(transaction)}
                          </Text>
                          <Text nonce={nonce} style={styles.transaction_amount}>
                            {/* {console.log('indiv tx amount ', getTransactionAmount(transaction))} */}
                            {/* {currencyUtils.formatCurrencyString(transaction.currency, transaction.amount)} */}
                            {getTransactionAmount(transaction)}
                          </Text>
                          <Text nonce={nonce} style={styles.transaction_date}>
                            {/* {console.log('indiv tx date',dateutils.getTransactionLastDateShort(transaction))} */}
                            {/* {dateUtils.formatDate(transaction.date)} */}
                            {dateUtils.getTransactionLastDateShort(transaction)}
                          </Text>
                        </div>
                      </>
                    )
                  })}
              </div>

              {/*Footer Image*/}
              <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
            </View>
          )
        })}

        {/*SUMMARY PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_sub_title}>
              WHAT HAPPENS NEXT?
            </Text>
            <div nonce={nonce} style={styles.summary_container}>
              <Text nonce={nonce} style={styles.page_body}>
                Everything owned by a person who has died is known as their estate. The estate may be made up of:
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · money, both cash and money in a bank or building society account. This could include money paid out on
                a life insurance policy
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · money owed to the person who has died
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · shares, property, for example, their home
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · personal possessions, for example, their car or jewellery
              </Text>
              <Text nonce={nonce} style={styles.page_body}>
                The estate of the person who has died is usually passed to surviving relatives and friends, either
                according to instructions in the will, or if the person dies without leaving a will, according to
                certain legal rules called the rules of intestacy.
              </Text>
              <Text nonce={nonce} style={styles.page_body}>
                The Snapshot report exists to help with all of the above, pulling all the information of a persons life
                into one place .........
              </Text>
            </div>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>
      </Page>
    </Document>
  )
}

const generateExecutorDocument = (transactions, contacts, user, accounts, doc_groups) => {
  // const generateExecutorDocument = (transactions, contacts, user, accounts, documents) => {
  const generatedDate = dateUtils.formatDateLong(new Date())
  const headerDate = dateUtils.formatDateReportHeader(new Date())

  const nonce = document.querySelector("meta[name=nonce]").nonce

  const chunk_size = 15
  const groups = []
  if (!accounts || !transactions) {
    throw new TypeError("accounts and/or transactions were not provided")
  }
  for (const acc of accounts) {
    const accountTransactions = transactions.filter((tx) => tx.account_id === acc.external_account_id)
    for (let i = 0; i < accountTransactions.length; i += chunk_size) {
      const chunk = accountTransactions.slice(i, i + chunk_size)
      groups.push({ account: acc, num: groups.length, trxs: chunk })
    }
  }

  user.dob_string = ""
  if (user.dob) user.dob_string = new Date(user.dob).toLocaleDateString("en-GB")

  return (
    <Document>
      <Page size="A4">
        {/*TITLE PAGE  backgroundColor: "#25325E" */}
        <View key={Math.random()} nonce={nonce} style={{ color: "#FFFFFF", height: "100%" }}>
          <Image src={title_background} nonce={nonce} style={styles.title_page_background} />
          <div nonce={nonce} style={{ marginLeft: "30px" }}>
            <Image src={logo} nonce={nonce} style={{ width: "30%", marginTop: "200px" }} />
            <Text nonce={nonce} style={{ width: "60%", fontSize: "50px", marginTop: "10px" }}>
              REPORT
            </Text>
            <Text nonce={nonce} style={{ fontSize: "25px", marginTop: "60px" }}>
              FOR EXECUTORS
            </Text>
            <Text
              nonce={nonce}
              style={{
                fontSize: "25px",
                marginTop: "5px",
                textTransform: "uppercase",
              }}
            >
              {generatedDate}
            </Text>
          </div>
        </View>

        {/*INTRO PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              HOW TO USE THE REPORT
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              The Report is an important tool that gives the grieving family, dealing with loss a useful overview of the
              financial affairs of the Subscriber
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              The information within this report can help in a number of ways:
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              It tells you which banks need to be informed so that accounts can be frozen or closed. The report lists
              regular payments, like subscriptions, direct debits and standing orders to help you identify companies
              that need to be informed.
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              It helps provide an understanding of the liquid assets that might be required to help with funeral and
              burial costs.
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              Within is a list of all documents and policies that have been uploaded. Will, Insurances, Shares and
              Policies.
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              There is a facility that can be used by the subscriber to provide instructions which can assist in the
              recovery of important benefits from work or elsewhere that may help everyone involved.
            </Text>
            <Text nonce={nonce} style={styles.summary_body}>
              Finally there is a list important contacts that may need to be informed following the death of our member.
            </Text>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*ABOUT PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              ABOUT THE MEMBER
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Full Name:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && `${user.first_name} ${user.last_name}`}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Date of Birth:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.dob_string}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Current Address:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.address}
            </Text>
            <Text nonce={nonce} style={styles.page_body_label}>
              Member From:
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && user.created_at && dateUtils.formatDateLong(user.created_at)}
            </Text>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*CONTACTS PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div key={Math.random()}>
            <Text nonce={nonce} style={styles.page_title}>
              KEY CONTACTS & NOMINATED PERSONS
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {user && `The following people were nominated by ${user.first_name} and may have access to this report.`}
            </Text>
            <Text nonce={nonce} style={styles.page_body_bold}>
              Nominated People
            </Text>

            {contacts &&
              contacts.map((contact, idx) => {
                return (
                  <>
                    {idx < 5 ? (
                      <div key={Math.random()} nonce={nonce} style={styles.contacts}>
                        <Text
                          nonce={nonce}
                          style={styles.contact_line_bold}
                        >{`${contact.first_name} ${contact.last_name}`}</Text>
                        <Text nonce={nonce} style={styles.contact_line}>{`Relationship: ${contact.relationship}`}</Text>
                        <Text nonce={nonce} style={styles.contact_line}>{`Email: ${contact.email}`}</Text>
                        <Text nonce={nonce} style={styles.contact_line}>{`Phone: ${contact.phone}`}</Text>
                      </div>
                    ) : null}
                  </>
                )
              })}
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*CONTACTS CONTINUED PAGE*/}
        {contacts && contacts.length > 5 ? (
          <View key={Math.random()} nonce={nonce} style={styles.page}>
            {/*Header*/}
            <div nonce={nonce} style={styles.header_row}>
              <Text nonce={nonce} style={styles.header_left}>
                EXECUTOR REPORT
              </Text>
              <Text nonce={nonce} style={styles.header_center}>
                //
              </Text>
              <Text nonce={nonce} style={styles.header_right}>
                {headerDate}
              </Text>
            </div>
            {/* Body */}
            <div>
              <Text nonce={nonce} style={styles.page_title}>
                KEY CONTACTS & NOMINATED PERSONS continued
              </Text>
              {contacts &&
                contacts.map((contact, idx) => {
                  return (
                    <>
                      {idx && idx >= 5 ? (
                        <div key={Math.random()} nonce={nonce} style={styles.contacts}>
                          <Text
                            nonce={nonce}
                            style={styles.contact_line_bold}
                          >{`${contact.first_name} ${contact.last_name}`}</Text>
                          <Text
                            nonce={nonce}
                            style={styles.contact_line}
                          >{`Relationship: ${contact.relationship}`}</Text>
                          <Text nonce={nonce} style={styles.contact_line}>{`Email: ${contact.email}`}</Text>
                          <Text nonce={nonce} style={styles.contact_line}>{`Phone: ${contact.phone}`}</Text>
                        </div>
                      ) : null}
                    </>
                  )
                })}
            </div>

            {/*Footer Image*/}
            <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
          </View>
        ) : null}

        {/*OUTGOINGS PAGE*/}
        <View key={"outgoings-view"} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div key={Math.random()}>
            <Text nonce={nonce} style={styles.page_title}>
              OPEN BANKING INFORMATION
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              {`Snapshot is linked to the main bank account or accounts of ${user.first_name} ${user.last_name}. Our system extracts key information from the accounts to highlight recurring payments, such as Direct Debits, Standing Orders, Subscriptions and any other Regular Payments. Once a death certificate has been issued and probate is granted, the family will have full access to the accounts and have a full view of all transactions`}
            </Text>
            <Text nonce={nonce} style={styles.page_body_bold}>
              Bank Accounts Linked to Snapshot
            </Text>

            {accounts &&
              accounts.map((account, _idx) => {
                return (
                  <>
                    <div key={Math.random()} nonce={nonce} style={styles.contacts}>
                      <Text
                        nonce={nonce}
                        style={styles.contact_line_bold}
                      >{`${account.provider} ${account.name}`}</Text>
                      <Text nonce={nonce} style={styles.contact_line}>{`Sort Code: ${account.sort_code}`}</Text>
                      <Text
                        nonce={nonce}
                        style={styles.contact_line}
                      >{`Account Number: ${account.account_number}`}</Text>
                    </div>
                  </>
                )
              })}
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*OUTGOINGS CONTINUED 2 PAGE*/}
        {groups.map((g, idx) => {
          return (
            <View key={Math.random()} nonce={nonce} style={styles.page}>
              {/*Header*/}
              <div nonce={nonce} style={styles.header_row}>
                <Text nonce={nonce} style={styles.header_left}>
                  EXECUTOR REPORT
                </Text>
                <Text nonce={nonce} style={styles.header_center}>
                  //
                </Text>
                <Text nonce={nonce} style={styles.header_right}>
                  {headerDate}
                </Text>
              </div>
              {/* Body */}
              <div>
                <Text nonce={nonce} style={styles.page_title}>
                  OPEN BANKING INFORMATION continued
                </Text>
                <Text
                  nonce={nonce}
                  style={styles.page_body_bold}
                >{`Active Regular Payments${idx > 0 ? " continued" : ""}`}</Text>

                <div nonce={nonce} style={styles.contacts}>
                  <Text nonce={nonce} style={styles.contact_line_bold}>
                    {g.account && `${g.account.provider} ${g.account.name}`}
                  </Text>
                  <Text nonce={nonce} style={styles.contact_line}>
                    {g.account && `Sort Code: ${g.account.sort_code}`}
                  </Text>
                  <Text nonce={nonce} style={styles.contact_line}>
                    {g.account && `Account Number: ${g.account.account_number}`}
                  </Text>
                </div>

                {g.trxs &&
                  g.trxs.map((transaction, _idx) => {
                    return (
                      <>
                        <div key={Math.random()} nonce={nonce} style={styles.transaction}>
                          <Text nonce={nonce} style={styles.transaction_name}>
                            {getMerchantName(transaction)}
                          </Text>
                          <Text nonce={nonce} style={styles.transaction_amount}>
                            {getTransactionAmount(transaction)}
                          </Text>
                          <Text nonce={nonce} style={styles.transaction_date}>
                            {dateUtils.getTransactionLastDateShort(transaction)}
                          </Text>
                        </div>
                      </>
                    )
                  })}
              </div>

              {/*Footer Image*/}
              <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
            </View>
          )
        })}

        {/*DOCUMENTS PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_title}>
              ADDITIONAL INFORMATION FOR
            </Text>
            <Text nonce={nonce} style={styles.page_title_second}>
              {user ? `${user.first_name.toUpperCase()} ${user.last_name.toUpperCase()}` : ""}
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              The Snapshot Account of all our subscribers contains a vault where members can choose to save certain
              documents or store valuable information if they wish.
            </Text>
            <Text nonce={nonce} style={styles.page_body}>
              Anything added to the vault is listed below and can be accessed by contacting the Snapshot Team who will
              provide the nominated persons with the secure logins to review and download this information immediately
            </Text>

            {doc_groups &&
              doc_groups[0] &&
              doc_groups[0].documents &&
              doc_groups[0].documents.map((document, idx) => {
                return (
                  <React.Fragment key={`${document.title}-${idx}`}>
                    <View nonce={nonce} style={styles.contacts}>
                      <Text
                        nonce={nonce}
                        style={styles.docs_line_bold}
                      >{`${document.type.toUpperCase()} - ${document.title}`}</Text>
                      {document.images.map((image, idx) => {
                        return (
                          <Image
                            key={`${document.title}-img-${idx}`}
                            src={image.src}
                            nonce={nonce}
                            style={{ maxWidth: "100%", height: "auto", aspectRatio: image.aspectRatio }}
                          />
                        )
                      })}
                      <Text nonce={nonce} style={styles.docs_line}>{`Value - ${document.value}`}</Text>
                      <Text nonce={nonce} style={styles.docs_line}>{`Description - ${document.description}`}</Text>
                      <Text nonce={nonce} style={styles.docs_line}>
                        {`Last Updated - ${dateUtils.formatDateLong(document.updated_at)}`}
                      </Text>
                    </View>
                  </React.Fragment>
                )
              })}
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>

        {/*DOCUMENTS PAGE CONTINUED*/}
        {doc_groups &&
          doc_groups.length > 1 &&
          doc_groups.slice(1, doc_groups.length).map((group, _idx) => {
            return (
              <View key={`docs-group-continued-${Math.random()}`} nonce={nonce} style={styles.page}>
                {/*Header*/}
                <div nonce={nonce} style={styles.header_row}>
                  <Text nonce={nonce} style={styles.header_left}>
                    EXECUTOR REPORT
                  </Text>
                  <Text nonce={nonce} style={styles.header_center}>
                    //
                  </Text>
                  <Text nonce={nonce} style={styles.header_right}>
                    {headerDate}
                  </Text>
                </div>
                {/* Body */}
                <div>
                  <Text nonce={nonce} style={styles.page_title}>
                    ADDITIONAL INFORMATION CONTINUED
                  </Text>

                  {group.documents &&
                    group.documents.map((document, idx) => {
                      return (
                        <React.Fragment key={`${document.title}-${idx}-continued`}>
                          <View nonce={nonce} style={styles.contacts}>
                            <Text
                              nonce={nonce}
                              style={styles.docs_line_bold}
                            >{`${document.type.toUpperCase()} - ${document.title}`}</Text>
                            {document.images.map((image, idx) => {
                              return (
                                <Image
                                  key={`${document.title}-img-${idx}`}
                                  src={image.src}
                                  nonce={nonce}
                                  style={{ maxWidth: "100%", height: "auto", aspectRatio: image.aspectRatio }}
                                />
                              )
                            })}
                            <Text nonce={nonce} style={styles.docs_line}>{`Value - ${document.value}`}</Text>
                            <Text
                              nonce={nonce}
                              style={styles.docs_line}
                            >{`Description - ${document.description}`}</Text>
                            <Text nonce={nonce} style={styles.docs_line}>
                              {`Last Updated - ${dateUtils.formatDateLong(document.updated_at)}`}
                            </Text>
                          </View>
                        </React.Fragment>
                      )
                    })}
                </div>

                {/*Footer Image*/}
                <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
              </View>
            )
          })}

        {/*SUMMARY PAGE*/}
        <View key={Math.random()} nonce={nonce} style={styles.page}>
          {/*Header*/}
          <div nonce={nonce} style={styles.header_row}>
            <Text nonce={nonce} style={styles.header_left}>
              EXECUTOR REPORT
            </Text>
            <Text nonce={nonce} style={styles.header_center}>
              //
            </Text>
            <Text nonce={nonce} style={styles.header_right}>
              {headerDate}
            </Text>
          </div>
          {/* Body */}
          <div>
            <Text nonce={nonce} style={styles.page_sub_title}>
              WHAT HAPPENS NEXT?
            </Text>
            <div nonce={nonce} style={styles.summary_container}>
              <Text nonce={nonce} style={styles.page_body}>
                Everything owned by a person who has died is known as their estate. The estate may be made up of:
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · money, both cash and money in a bank or building society account. This could include money paid out on
                a life insurance policy
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · money owed to the person who has died
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · shares, property, for example, their home
              </Text>
              <Text nonce={nonce} style={styles.summary_list}>
                · personal possessions, for example, their car or jewellery
              </Text>
              <Text nonce={nonce} style={styles.page_body}>
                The estate of the person who has died is usually passed to surviving relatives and friends, either
                according to instructions in the will, or if the person dies without leaving a will, according to
                certain legal rules called the rules of intestacy.
              </Text>
              <Text nonce={nonce} style={styles.page_body}>
                The Snapshot report exists to help with all of the above, pulling all the information of a persons life
                into one place .........
              </Text>
            </div>
          </div>

          {/*Footer Image*/}
          <Image src={logo_dark} nonce={nonce} style={styles.footer_image} />
        </View>
      </Page>
    </Document>
  )
}

export default {
  generateFinancialDocument,
  generateExecutorDocument,
}
