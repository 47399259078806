import { useEffect, useState } from "react";
import users_api from "../../api/users";
import { useNavigate } from "react-router-dom";
import LoginRegisterSideImage from "../../components/LoginRegisterSideImage";

const Verify2FA = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")

    const [verificationLetter1, setVerificationLetter1] = useState("")
    const [verificationLetter2, setVerificationLetter2] = useState("")
    const [verificationLetter3, setVerificationLetter3] = useState("")
    const [verificationLetter4, setVerificationLetter4] = useState("")
    const [verificationLetter5, setVerificationLetter5] = useState("")
    const [verificationLetter6, setVerificationLetter6] = useState("")

    const handleVerify = (event) => {
        event.preventDefault();
        setError("")
    }

    const CheckVerificationData = () => {
        let string = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        let lengthCheck = string.length === 6
        return [lengthCheck].every(Boolean);
    }

    const handleVerifySubmit = (event) => {
        if (!CheckVerificationData) setError("The data you have inputted is invalid")
        let verificationCode = (verificationLetter1 + verificationLetter2 + verificationLetter3 + verificationLetter4 + verificationLetter5 + verificationLetter6)
        VerifyUserPhone(verificationCode);
    }

    const VerifyUserPhone = (verificationCode) => {
        if (!verificationCode) return;
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
            "code": verificationCode,
        }
        let response = users_api.verify2FA(formData);
        response.then(
            response => {
                if (response.data.error) {
                    setMessage("");
                    setError(response.data.error)
                }
                else {
                    navigate("/dashboard");
                }
            }
        ).catch((err) => {
            setMessage("");
            setError("Verification 2fa failed")
            console.log(err)
        })
    }

    const SendUserText = () => {
        setMessage("");
        setError("");
        setVerificationLetter1("");
        setVerificationLetter2("");
        setVerificationLetter3("");
        setVerificationLetter4("");
        setVerificationLetter5("");
        setVerificationLetter6("");
        let formData = {
            "apiKey": process.env.REACT_APP_SERVER_API_KEY,
        }
        let response = users_api.resend2FA(formData);
        response.then(
            response => {
                if (response.data && response.data.error) {
                    setError("Authentication Code failed to resend");
                }
                else
                    setMessage("Authentication Code has been resent")
            }
        ).catch((err) => {
            setError("Authentication Code failed to resend");
        })
    }


    return (
        <>
            <div className="login-page-container">
                <LoginRegisterSideImage />
                <div className="mfa-subpage-container">
                    <div className="mfa-page-verification-container" >
                        <form name="mfa-form" onSubmit={handleVerify}>
                            <div className="mfa-page-title">
                                Multi-factor Authentication
                            </div>
                            <div className="mfa-page-subtitle verification">
                                Mobile verification
                            </div>
                            <div className="mfa-page-description verification">
                                We’ve sent a 6 character code to your mobile number for authentication purposes. The code will expire after 2 minutes.
                            </div>
                            <div className="verify-form-container">
                                <input type='text' id='verify-mob-1' className='verify-form-character' value={verificationLetter1}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter1(ev.target.value)
                                        if (ev.target.value !== "") document.getElementById('verify-mob-2').focus();
                                    }} />
                                <input type='text' id='verify-mob-2' className='verify-form-character' value={verificationLetter2}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter2(ev.target.value)
                                        if (ev.target.value !== "") document.getElementById('verify-mob-3').focus();
                                    }} />
                                <input type='text' id='verify-mob-3' className='verify-form-character' value={verificationLetter3}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter3(ev.target.value)
                                        if (ev.target.value !== "") document.getElementById('verify-mob-4').focus();
                                    }} />
                                <input type='text' id='verify-mob-4' className='verify-form-character' value={verificationLetter4}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter4(ev.target.value)
                                        if (ev.target.value !== "") document.getElementById('verify-mob-5').focus();
                                    }} />
                                <input type='text' id='verify-mob-5' className='verify-form-character' value={verificationLetter5}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter5(ev.target.value)
                                        if (ev.target.value !== "") document.getElementById('verify-mob-6').focus();
                                    }} />
                                <input type='text' id='verify-mob-6' className='verify-form-character' value={verificationLetter6}
                                    maxLength='1' onChange={(ev) => {
                                        setVerificationLetter6(ev.target.value)
                                    }} />
                            </div>

                            <div className="mfa-error-message">
                                {error && error}
                            </div>
                            <div className="mfa-message">
                                {message && message}
                            </div>

                            <div className="verify-submit-button-container">
                                <input type="submit" value="Verify" className="verify-submit-button link"
                                    disabled={!CheckVerificationData()} onClick={handleVerifySubmit} />
                            </div>

                            <div className="verify-resend-code link" onClick={(ev) => SendUserText(true)}>
                                Resend verification code
                            </div>
                            <div className="return-to-details link" onClick={(ev) => navigate('/login')}>
                                Return to login
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Verify2FA
